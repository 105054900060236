<script setup>

</script>

<template>
  <br>
  <div class="cover text-center mt-4 py-5"><br>
    <h1 class="py-5 text-white fw-bold">About Us</h1><br>
  </div>

  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-md-4">
        <h5>About Us</h5>
        <p>
          Kontoh Educational Complex (KEC) is a private school committed to academic excellence
          through focus education, service, fostering intellectual curiosity, self-directed
          learning, and the individual development of each child.
        </p>
        <p>
          Kontoh Educational Complex comprises over 250 learners aged 1-16. KEC has committed
          itself to educate and develop the young people entrusted to our care by our diverse
          community of families our values of hard work respect for one another our child-friendly
          environment and teamwork guide our daily processes. Our goal is to be a model school
          where teachers, staff, learners, and parents work together to create and foster an
          educational center of excellence. We welcome all who share our ethos and IDEALS
          (Helping Children to Believe, Achieve, and Succeed), AKWAABA!!
        </p>
      </div>

      <div class="col-md-4 mt-3">
        <h5>History</h5>
        <p>
          The Kontoh Educational Complex, Ghana was founded by Mr. Nat Kontoh upon his
          return from the USA. Mr. Nat Kontoh had come to Ghana after having spent six
          years studying and living in the UK. Mr. Kontoh acquired land at Ekyem, Kumasi,
          upon entering the community, he saw that there was no school in the community and its
          surrounding which makes it difficult for the children to access education. Having
          difficulties finding a school that in their view was a good fit for children within the
          community and beyond. He decides to start one himself.
        </p>
        <p>
          Kontoh Educational complex (KEC) formally opened its doors to the public with six
          students in January 2014. KEC now has over 250 students from preschool through primary,
          we pride ourselves in our rich cultural diversity with our student body consisting of
          learners from more than two different nationalities. A student learning environment is
          important and KEC provides the perfect environment with our beneficial 5-acre campus
          outfitted with sizeable classrooms, well-stocked computer labs, and many other facilities.
        </p>

      </div>

      <div class="col-md-4">
        <h5>Vision</h5>
        <p>
          All learners find pathways to success through the independence, innovation, and
          diversity of our schools, creating a more equitable world.
        </p>
        <h5>Mission</h5>
        <p>
          We co-create the future of education by uniting and empowering our community.
          We do this through thought leadership, research, creation and curation of
          resources, and direct collaboration with education leaders.
        </p>
        <h5>Values</h5>
        <p>
          Thinking independently: We believe in independence and its power to inspire excellence.
        </p>
          <ul>
              <li> Leading change: We imagine possibilities and innovate to strengthen the education landscape.</li>
              <li>Embracing diversity: We welcome and encourage diverse identities and perspectives.</li>
              <li>Championing inclusivity: We affirm the rights of every individual to belong and flourish.</li>
              <li>Empowering community: We address complex issues through collaboration and advocacy.</li>
          </ul>


      </div>
    </div>
  </div>

</template>


<style scoped>
.cover {
  background: url("../../public/img/about/cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>